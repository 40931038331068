import { memo, ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Accordion, AccordionPanel, AccordionProps } from './accordion'
import { Pill } from '../../pill'
import { Box } from '../box'
import { useMutationObserver } from '../../utilities'

type FilterAccordionProps = AccordionProps & {
  isLayoutFilter?: boolean
}

export const FilterAccordion = ({ isLayoutFilter, ...restProps }: FilterAccordionProps) => {
  const isFilterPanelOpen = useFilterPanelOpenState()
  const [disableAnimation, setDisableAnimation] = useState<boolean | undefined>(!isFilterPanelOpen)

  useEffect(() => {
    if (!isFilterPanelOpen) {
      setDisableAnimation(true)
    } else {
      setDisableAnimation(undefined)
    }
  }, [isFilterPanelOpen])

  return <StyledFilterAccordion disableAnimation={disableAnimation} isLayoutFilter={isLayoutFilter} {...restProps} />
}

type FilterAccordionPanelProps = {
  label: string
  children: ReactNode
  applied?: boolean
  // TODO: check if we still want to enable overriding this
  /** defaults to "applied" */
  appliedLabel?: string
  initialActive?: boolean
  suffix?: ReactNode
  a11yTitle?: string
}

export const FilterAccordionPanel = memo(
  ({ label, children, applied, appliedLabel, ...restProps }: FilterAccordionPanelProps) => {
    return (
      <AccordionPanel
        label={label}
        labelSuffix={
          !!applied ? (
            <Pill css="margin-left: 4px; flex-shrink: 0;" label={appliedLabel ?? 'applied'} size="small" />
          ) : undefined
        }
        {...restProps}
      >
        <Box
          flex={false}
          css={`
            > div {
              // why did i do this? keeping is strews up something now but keeping while in dev bc i remember it fixing
              // something very hard to identify...and if something comes up in testing i want to remember.
              // TODO: remove before merging
              /* display: block; */
            }
          `}
        >
          {children}
        </Box>
      </AccordionPanel>
    )
  }
)

export const useFilterPanelOpenState = () => {
  const root = document.getElementById('page-root')
  const [filterPanelElement, setFilterPanelElement] = useState<HTMLElement | null>(null)
  const [isOpen, setIsOpen] = useState(() => {
    if (!root) return false

    return root.classList.contains('filter-panel-open')
  })

  useEffect(() => {
    if (!root) return
    if (!filterPanelElement) setFilterPanelElement(document.getElementById('layout-filter-panel'))

    root.classList.contains('filter-panel-open') ? setIsOpen(true) : setIsOpen(false)
  }, [root])

  useMutationObserver(
    filterPanelElement,
    mutations => {
      for (const mutation of mutations) {
        if (mutation.attributeName === 'class') {
          ;(mutation.target as HTMLElement).classList.contains('open') ? setIsOpen(true) : setIsOpen(false)
        }
      }
    },
    { childList: false, attributes: true }
  )

  return isOpen
}

// TODO: check what/if styles needed
// TODO: doesn't seem right that this references things that are defined in layout in the app
// but would like to have filter accordion stories so will resolve if we ever combine react-ui and the app
export const StyledFilterAccordion = styled(Accordion)<FilterAccordionProps>`
  ${({ isLayoutFilter }) =>
    isLayoutFilter &&
    css`
      #page-root:not(.filter-panel-open) & {
        transition: none;
      }

      .accordion-item-content {
        opacity: 1;

        #page-root.filter-panel-open & {
          opacity: 1;
        }
      }
    `}
`

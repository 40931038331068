import { ReactNode, useEffect, useState } from 'react'

import { useFilterPanelOpenState } from './layout-hooks'

// We need to defer rendering the content of the panel on first it opens so that the filter
// panel open animation can complete before the content renders. This is needed for our
// text component to track properly the available space and know whether it should truncate
// with a tooltip for overflows.

export const FilterPanel = ({ children }: { children: ReactNode }) => {
  const isFilterPanelOpen = useFilterPanelOpenState()
  const [initialOpen, setInitialOpen] = useState<boolean | null>(isFilterPanelOpen)

  useEffect(() => {
    if (isFilterPanelOpen) setInitialOpen(true)
  }, [isFilterPanelOpen])

  return <>{initialOpen && children}</>
}
